<template>
    <router-link v-if="href !== ''" :to="href" :class="mode">
        <span class="icon" v-if="assetIcon !== undefined"><img :src="iconUrl" /></span>
        <span class="title" v-if="title !== undefined">{{ $Lang.getTitle(title) }}</span>
    </router-link>
    <a v-else :class="mode">
        <span class="icon" v-if="assetIcon !== undefined"><img :src="iconUrl" /></span>
        <span class="title" v-if="title !== undefined">{{ $Lang.getTitle(title) }}</span>
    </a>
</template>

<script>
export default {
    props: {
        href: { type:String, default:'' },
        title: null,
        mode: String,
        assetIcon: String
    },
    computed: {
        iconUrl() {
            if (this.assetIcon !== undefined) return require('@/assets/' + this.assetIcon)
            return ""
        }
    }
}
</script>

<style scoped lang="scss">
a {
    background-color:var(--button-link-background-color);
    color:var(--button-link-text-color);
    box-sizing:border-box;
    font-size:var(--button-link-font-size);
    line-height:var(--button-link-line-height);
    display:inline-block;
    text-decoration:none;
    vertical-align:top;
    min-width:145px;
    text-align:center;
    padding-left:10px;
    padding-right:10px;
    box-sizing:border-box;
    border-radius:4px;
    padding-top:3px;
    cursor:pointer;
    
    .icon {vertical-align:middle; }
    img {
        transform:translateZ(0);
        display:inline-block;
        max-height:19px;
        margin-top:4px;
        vertical-align:top;
    }

    .title {
        margin-left:8px;
        color:var(--button-link-text-color);
        display:inline-block;
        line-height:17px;
    }
    &:hover {
        background-color:var(--button-background-color-hover);
    }

    &.light {
        background-color:var(--button-light-background-color);
        &:hover {
            background-color:var(--button-light-background-color-hover);
        }
    }
}
@media only screen and (max-width:768px) {
    a {
        min-width:inherit;
        font-size:var(--button-link-font-size-mini);
    }
}
</style>
